.category-tag {
  border: none;
}

.tag-clip-button {
  background: rgba(106, 72, 198);
  border-radius: 4px;
  width: 20px;
  height: 20px;
  border: none;
  max-width: 20px;
  padding: 0px;
  -webkit-box-shadow: none;
  box-shadow: none;
  top: 1px;
}

.tag-clip-button span {
  color: @cc-purple;
  height: 20px;
  text-shadow: none;
}
